<template>
  <div class="box">
    <div class="box__header" v-if="title">
      <h2 class="box__header-title box__header-title--green">{{ $t(title) }}</h2>
    </div>
    <div class="box__body">
      <div class="o-documents-box" v-if="documents.length">
        <div class="o-documents-box__grid">
          <component
            v-for="doc in pagedDocuments"
            :key="doc.id"
            :is="doc.link ? 'a' : 'div'"
            :href="doc.link || ''"
            class="o-documents-box__row"
          >
            <div class="o-documents-box__col o-documents-box__col--title">
              <div class="o-documents-box__doc-title">{{ doc.name }}</div>
              <div class="o-documents-box__doc-type">{{ docTypeText(doc.type) }}</div>
            </div>
            <div class="o-documents-box__col">{{ numberOfDaysSince(doc.date) }}</div>
            <div class="o-documents-box__col o-documents-box__col--download">
              <a class="project-docs__btn btn btn-download" v-if="doc.link" :href="doc.link" v-tooltip="$t('download')"></a>
            </div>
          </component>
        </div>
      </div>
      <template v-else>
        <blockquote>{{ $t('noDocumentsDesc') }}</blockquote>
      </template>
      <paging-gcr v-if="paging" :callback="changePage" :pageCount="pageCount" />
    </div>
  </div>
</template>

<script>
import { Dates } from '@/_helpers'
import PagingGcr from '@/components/molecules/Paging'

require('../../utilities/button')
require('../../utilities/box')

export default {
  components: { PagingGcr },
  data() {
    return {
      pageIndex: 1
    }
  },
  props: {
    title: String,
    documents: Array,
    documentsTypes: Array,
    paging: {
      type: Number,
      default: 0
    }
  },
  methods: {
    numberOfDaysSince(date) {
      return Dates.formatDays(Dates.diffDays(date, new Date()))
    },
    changePage(index) {
      this.pageIndex = index
    }
  },
  computed: {
    docTypeText() {
      return value => {
        const match = this.documentsTypes.filter(obj => obj.value === value)
        return match && match[0] ? this.$t(match[0].text) : ''
      }
    },
    pagedDocuments() {
      if (!this.paging) {
        return this.documents
      }
      return this.documents.slice((this.pageIndex - 1) * this.paging, this.pageIndex * this.paging)
    },
    pageCount() {
      return Math.ceil(this.documents.length / this.paging)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

.o-documents-box {
  &__grid {
    border: 1px solid gray('gray-150');
    border-bottom: 0;
  }
  &__row {
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0;
    @media screen and (min-width: breakpoint-min('md')) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    border-bottom: 1px solid gray('gray-150');
    position: relative;
    background-color: gray('white');
    color: gray('gray-700');
    text-decoration: none;
    &:hover {
      background-color: gray('gray-100');
    }
  }
  &__col {
    padding: 0 0.75rem;
    font-size: 14px;
    min-width: 25%;

    &--download {
      min-width: calc(36px + 1.5rem);
    }
    &--title {
      width: calc(75% - (36px + 1.5rem));
      @media screen and (min-width: breakpoint-min('md')) {
        width: 100%;
      }
    }
  }
  &__doc-title {
    font-weight: 500;
    font-size: 17px;
  }
}
</style>
